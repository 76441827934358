import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/capetimes-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const CapeTimes = (props) => (
  <Switch>
    <Route path='/capetimes' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes'
        sectionLabel='Cape Times'
        relatedSections={[{ id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/news' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/news'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/world' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/world'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/world-cup' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/world-cup'
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/sport' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/sport'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/business' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/business'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/opinion' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/opinion'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/technology' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/technology'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/lifestyle' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/lifestyle'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/arts-portal' }, { id: 'capetimes/escape-times' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal/stage' }, { id: 'capetimes/arts-portal/adventure' }, { id: 'capetimes/arts-portal/film' }, { id: 'capetimes/arts-portal/visual-arts' }, { id: 'capetimes/arts-portal/music' }, { id: 'capetimes/arts-portal/food-and-wine' }, { id: 'capetimes/arts-portal/leisure' }, { id: 'capetimes/arts-portal/lifestyle' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/stage' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/stage'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/adventure' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/adventure'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/film' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/film'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/visual-arts' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/visual-arts'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/music' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/music'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/food-and-wine' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/food-and-wine'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/leisure' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/leisure'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/arts-portal/lifestyle' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/arts-portal/lifestyle'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path='/capetimes/escape-times' exact render={() => (
      
      <TitleSectionPage {...props}
        section='capetimes/escape-times'
        relatedSections={[{ id: 'capetimes', label: 'Cape Times' }, { id: 'capetimes/news' }, { id: 'capetimes/world' }, { id: 'capetimes/sport' }, { id: 'capetimes/business' }, { id: 'capetimes/opinion' }, { id: 'capetimes/technology' }, { id: 'capetimes/lifestyle' }, { id: 'capetimes/arts-portal' }]}
        title='Cape Times'
        description='-'
        publication='Cape Times'
        newspaperTitle='The Cape Times on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://capetimes.zinioapps.com/shop'
      />
    
    )} />
    <Route path={'/capetimes/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/capetimes/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/capetimes/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/capetimes/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/capetimes/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    
    )} />
  </Switch>
)

export default CapeTimes
